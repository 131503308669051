<template>
    <section>
        <div id="sign-in-button" />
        <div class="row mx-0 px-3 pl-5 my-1 f-600 border-bottom py-2 justify-content-between align-items-center">
            <p class="text-general f-18">Datos de acceso</p>
        </div>
        <div class="p-3 f-15 custom-scroll alto-content">
            <div class="row p-2">
                <div class="col">
                    <div :class="{'bg-light-f5': _.get(data, 'telefono')}" class="border br-20 cr-pointer d-flex justify-content-around p-2 text-center w-75" @click="modalVincular(false)">
                        <i class="icon-mobile f-17" />
                        <span class="text-general px-2">Vinculado a teléfono</span>
                        <i :class="{'icon-ok-circled-outline': _.get(data, 'telefono')}" class=" text-success f-17" />
                    </div>
                </div>
                <div class="col">
                    <img :src="data.bandera" width="40px" height="40px" class="obj-cover" alt="" />
                    {{ _.get(data, 'telefono') }}
                </div>
            </div>
            <!-- <div class="row p-2">
                <div class="col">
                    <div :class="{'bg-light-f5': _.get(data, 'correo')}" class="border br-20 cr-pointer d-flex justify-content-around p-2 text-center w-75" @click="modalVincular(true)">
                        <i class="icon-mail f-17" />
                        <span class="text-general px-2">Vincular a correo electrónico</span>
                        <i :class="{'icon-ok-circled-outline': _.get(data, 'correo')}" class=" text-success f-17" />
                    </div>
                </div>
                <div class="col">
                    {{ _.get(data, 'correo') }}
                </div>
            </div> -->
            <div class="row p-2">
                <div class="col">
                    <div :class="{'bg-light-f5': _.get(data, 'google')}" class="border br-20 cr-pointer d-flex justify-content-around p-2 text-center w-75" @click="onRegisterWithGoogle()">
                        <img src="/img/auth/google.svg" width="25px" height="25px" class="obj-cover" alt="" />
                        <span class="text-general px-2">Vincular a Google</span>
                        <i :class="{'icon-ok-circled-outline': _.get(data, 'google')}" class=" text-success f-17" />
                    </div>
                </div>
                <div class="col" />
            </div>
            <!-- <div class="row p-2">
                <div class="col">
                    <div :class="{'bg-light-f5': _.get(data, 'facebook')}" class="border br-20 cr-pointer d-flex justify-content-around p-2 text-center w-75" @click="onRegisterWithFacebook()">
                        <img src="/img/auth/facebook.svg" width="25px" height="25px" class="obj-cover" alt="" />
                        <span class="text-general px-2">Vincular a Facebook</span>
                        <i :class="{'icon-ok-circled-outline': _.get(data, 'facebook')}" class=" text-success f-17" />
                    </div>
                </div>
                <div class="col" />
            </div> -->
            <div class="row p-2">
                <div class="col">
                    <div :class="{'bg-light-f5': _.get(data, 'password')}" class="border br-20 cr-pointer d-flex justify-content-around p-2 text-center w-75" @click="modalAsignarContrasena()">
                        <i class="icon-lock f-17" />
                        <span class="text-general px-2">Asignar contraseña</span>
                        <i :class="{'icon-ok-circled-outline': _.get(data, 'password')}" class=" text-success f-17" />
                    </div>
                </div>
                <div class="col" />
            </div>
            <!-- <div class="row p-2">
                <div class="col">
                    <span class="float-right px-2 text-general w-50 cr-pointer" @click="recuperarContrasena()">Recuperar Contraseña</span>
                </div>
                <div class="col" />
            </div> -->
        </div>
        <!-- Partials -->
        <modal-recuperar-contrasena ref="modalRecuperarContrasena" />
        <modal-asignar-contrasena ref="modalAsignarContrasena" :show-last-password="_.get(data, 'password', false)" @update="getDatos()" />
        <modal-vincular ref="modalVincular" :is-email="isEmail" :bandera="data.bandera" @update="getDatos()" />
    </section>
</template>

<script>
import DatosAcceso from "../../../services/configurar/tendero-vip/datos_acceso";

import firebase from '~/library/firebase';
export default {
    components: {
        modalRecuperarContrasena: () => import('~/pages/auth/partials/recuperarContrasena'),
        modalVincular: () => import('./partials/modalVincular'),
        modalAsignarContrasena: () => import('./partials/modalAsignarContrasena'),
    },
    data(){
        return {
            ver_pass1: false,
            ver_pass2: false,
            model: {
                correo: '',
                telefono: '',
                password: '',
                new_password: '',
                pass_confirmar: ''
            },
            reglas: {
                contrasena: {
                    min: 8,
                    max: 200,
                    regex: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*.?\-_\/&:,;{}+*'¿¡!]?)([A-Za-z\d@$!.%*?\-_\/&:,;{}+*'¿¡!]){8,200}$/
                }
            },
            data: {},
            isEmail: false,
        }
    },
    mounted(){
        this.getDatos()
    },
    methods: {
        modalVincular(isEmail = false){
            this.isEmail = isEmail
            this.$refs.modalVincular.toggle();
        },
        modalAsignarContrasena(){
            this.$refs.modalAsignarContrasena.toggle()
        },
        async getDatos(){
            try {
                const { data } = await DatosAcceso.fetchDatos(this.$usuario.tienda.id)
                // this.model.correo = data.data.correo
                // this.model.telefono = data.data.telefono
                this.data = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async onRegisterWithGoogle(){
            const proveedor = new firebase.auth.GoogleAuthProvider();
            proveedor.addScope('email');
            this.abrirPopoverConexion(proveedor);
        },
        async onRegisterWithFacebook(){
            const proveedor = new firebase.auth.FacebookAuthProvider();
            proveedor.addScope('email');
            this.abrirPopoverConexion(proveedor);
        },
        async abrirPopoverConexion(proveedor){
            try {
                const { credential: credenciales, user } = await firebase.auth().signInWithPopup(proveedor);
                if (!credenciales) return;
                let isGoogle = /google.com/.test(proveedor.providerId)

                const payload = {
                    token: credenciales.accessToken,
                    provider: isGoogle ? 'google': 'facebook',
                    id_user: this.$usuario.id
                };
                const { data } = await DatosAcceso.vincularSocial(payload)
                if(data.exito){
                    this.notificacion('', 'Se ha vinculado correctamente', 'success')
                    this.getDatos()
                }
                /* if (isGoogle){
                    await this.registerWithGoogle(payload)
                } else {
                    await this.registerWithFacebook(payload)
                } */
            } catch (error){
                this.error_catch(error)
            }
        },
        async consultarProyecto(){
            try {
                const { data: { data }, } = await ServicioApi.consultarProyecto();
                this.proyecto.datos = data;
            } catch (error){
                this.error_catch(error);
            }
        },
        async consultarPaisesProyecto(proyecto){
            try {
                const { data: { data }, } = await ServicioApi.consultarPaises(proyecto);
                this.proyecto.paises = data;
            } catch (error){
                this.error_catch(error);
            }
        },
        
        async updateDatos(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(valid){

                    const {data} = await DatosAcceso.updateDatos(this.$usuario.tienda.id, this.model)
                    this.notificacion('', data.message, 'success')
                    this.getDatos()
                    this.model = {
                        correo: '',
                        telefono: '',
                        password: '',
                        new_password: '',
                        pass_confirmar: ''
                    }
                    this.$refs.validacion.reset()
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        recuperarContrasena(){
            this.$refs.modalRecuperarContrasena.toggle();
        }
    },
}
</script>
